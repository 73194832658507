nav {
	background: rgb(25, 25, 25);
	border-bottom: 1px solid var(--navline);
}

nav ul {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0;
}

nav ul li {
	display: inline-block;
	list-style: none;
	border-right: 1px solid var(--navline);
	padding: 0 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 5rem;
}

.nav-primary {
	margin: 0;
}

nav .navbar > ul.nav-primary > a:first-child {
	border-left: 1px solid var(--navline);
}

nav a:link {
	text-decoration: none;
}

nav a h2:hover {
	text-decoration: underline;
	text-decoration-color: white;
	text-decoration-thickness: 1px;
	text-underline-offset: 4px;
}

nav h1 {
	font-family: 'Marcellus';
	color: white;
	font-size: 1.75rem;
}

nav h2 {
	font-family: 'Marcellus';
	color: white;
	font-size: 1.25rem;
}

nav select {
	font-family: 'Marcellus';
	color: white;
	outline: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	font-size: 1.25rem;
	font-weight: bolder;
	background-color: rgba(0, 0, 0, 0);
	border: none;
	cursor: pointer;
	width: 40px;
}

.select-wrapper {
	width: 64px;
	display: flex;
	justify-content: center;
	height: 5rem;
	padding: 0 20px;
	margin: 0 20px;
	border-right: 1px solid var(--navline);
	border-left: 1px solid var(--navline);
	position: relative;
}

nav .select-wrapper::before {
	font-family: fontello;
	content: '\2228';
	font-size: 20px;
	position: absolute;
	right: 25%;
	top: 30%;
	color: white;
	pointer-events: none;
}

nav select::-ms-expand {
	display: none;
}

.dropdown-menu {
	z-index: 1;
	font-family: 'Marcellus';
	color: white;
	display: none;
	position: absolute;
	background-color: var(--navblacksec);
	min-width: 100%;
	left: 0;
	align-self: flex-start;
	margin-top: 5rem;
	box-shadow: 0px 6px 11px 3px rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0px 6px 11px 3px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0px 6px 11px 3px rgba(0, 0, 0, 0.5);
	border-left: 1px solid var(--navline);
	border-right: 1px solid var(--navline);
	border-bottom: 1px solid var(--navline);
}

.dropdown-menu li {
	border-top: 1px solid var(--navline);
	border-right: none;
	height: 4rem;
	display: flex;
	justify-content: center;
}

.dropdown-menu li h2 {
	font-size: 1rem;
}

.dropdown-opener {
	position: relative;
	display: flex;
	cursor: pointer;
}

.dropdown-opener:hover {
	text-decoration: underline;
	text-decoration-color: white;
	text-decoration-thickness: 1px;
	text-underline-offset: 4px;
}
.dropdown-opener:hover .dropdown-menu {
	display: block;
}

.spirit {
	height: 4.5rem;
}

.logo {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 20px;
}

.burger {
	display: none;
}

.mobile-grow-1 {
	flex-grow: 0;
}

.mobile-center-fix {
	padding: 0;
}

.navbarTitle {
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	font-size: 2rem;
	color: white;
	font-weight: lighter;
}

@media (max-width: 1024px) {
	.nav-primary {
		display: none;
	}

	.burger {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0.25rem 1.25rem;
		border: 1px solid var(--navline);
		border-radius: 0.25rem;
		width: 3.5rem;
		height: 3.5rem;
	}

	.burger > img {
		opacity: 0.8;
	}

	.mobile-grow-1 {
		flex-grow: 1;
	}

	.mobile-center-fix {
		padding-right: 35px;
	}
}

@media (max-width: 735px) {
	.select-wrapper {
		border: none;
		padding: 0;
	}
}

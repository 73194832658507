@font-face {
	font-family: 'Marcellus';
	src: url(./assets/fonts/Marcellus-Regular.ttf);
}
:root {
	--navblack: #191919;
	--navblacksec: #454545;
	--navline: #666666;
	--canvasgrey: #2e2e2e;
	--creamwhite: #d7d7d7;
	--tableeven: #b4b4b4;
	--tableodd: #a0a0a0;
	--gold: #ffc70f;
	--red: #c43131;
	--naviblue: #232d38;
}

body {
	margin: 0;
	background-color: var(--canvasgrey);
}

h1 {
	font-weight: 100;
}

h2 {
	font-weight: 100;
}

.row {
	display: flex;
	flex-direction: row;
}

.column {
	display: flex;
	flex-direction: column;
}

.justify-center {
	justify-content: center;
}

.justify-spacebetween {
	justify-content: space-between;
}

.justify-evenly {
	justify-content: space-evenly;
}

.justify-start {
	justify-content: flex-start;
}

.justify-end {
	justify-content: flex-end;
}

.justify-spacearound {
	justify-content: space-around;
}

.align-center {
	align-items: center;
}

.wrap {
	flex-wrap: wrap;
}

.nowrap  {
	flex-wrap: nowrap;
}

.wrap-reverse {
	flex-wrap: wrap-reverse;
}

/* table */

@media (max-width: 1024px) {
	div.table-scroll {
		width: 100%;
		overflow: auto;
	}
}

table {
	background-color: var(--creamwhite);
	border-collapse: collapse;
}

table thead {
	font-family: 'Marcellus';
	background-color: var(--creamwhite);
}

table tbody {
	font-family: 'Marcellus';
}

table tbody tr {
	height: 50px;
}

table tbody tr td {
	border-right: 1px solid var(--creamwhite);
	border-bottom: 0;
	border-top: 0;
	text-align: center;
}

table tbody tr td:nth-last-child(1) {
	border-right: 0;
	border-bottom: 0;
	border-top: 0;
}

table tbody tr:nth-child(odd) {
	background-color: var(--tableodd);
}

table tbody tr:nth-child(even) {
	background-color: var(--tableeven);
}

table tbody tr td {
	padding: 0;
	margin: 0;
}

@media only screen and (max-width: 600px) {
	table thead {
		font-size: 0.75rem;
	}

	table tbody {
		font-size: 0.75rem;
	}
}

.enhancement h1 {
	color: white;
	font-family: 'Marcellus';
}

.enhancementTable {
	max-height: 500px;
	overflow-y: scroll;
}

.enhancementTable table thead tr th {
	width: 100px;
	position: sticky;
	top: 0;
	background-color:  var(--creamwhite);
}

.enhancementTable table tbody tr:hover {
	background-color: var(--naviblue);
	color: white;
}

.enhancementTable table tbody tr td:hover {
	outline: 5px solid var(--gold);
}
.schedule-container {
	overflow: scroll;
	min-width: 730px;
}

table.schedule {
	margin: 50px auto auto auto;
}

table.schedule thead {
	font-size: 1.5rem;
}

table.schedule thead tr th {
	width: 150px;
	height: 40px;
}

table.schedule tbody {
	font-size: 1.25rem;
}

table.schedule tbody tr {
	height: 58px;
}

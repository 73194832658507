.bracket {
	margin: 0 10px;
}

.bracket table{
	width: 15rem;
}

.bracket h1 {
	color: white;
	font-family: 'Marcellus';
}
ul.bosstimer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0;
	min-width: 150px;
}
ul.bosstimer li {
	list-style: none;
	color: white;
	border: none;
	display: auto;
	font-family: Marcellus;;
	height: auto;
	font-size: 1.25rem;
}

.bossImage{
	height: 70px;
	width: 70px;
	border: 2px solid white;
	border-radius: 100%;
}